import { extend, localize } from "vee-validate";
import {
    email as rule_email,
    min as rule_min,
    confirmed as rule_confirmed,
    regex as rule_regex,
    between as rule_between,
    alpha as rule_alpha,
    integer as rule_integer,
    digits as rule_digits,
    alpha_dash as rule_alpha_dash,
    alpha_num as rule_alpha_num,
    length as rule_length,
} from "vee-validate/dist/rules";
//import en from "vee-validate/dist/locale/en.json";

// eslint-disable-next-line object-curly-newline
import {
    validatorPositive,
    validatorUrlValidator,
    validatorPassword,
    validatorCreditCard,
} from "./validators";

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend("required", {
    validate(value) {
        return {
            required: true,
            valid: ["", null, undefined].indexOf(value) === -1,
        };
    },
    message: "O campo {_field_} é obrigatório",
    computesRequired: true,
});

export const email = extend("email", rule_email);

export const min = extend("min", rule_min);

export const confirmed = extend("confirmed", rule_confirmed);

export const terms = extend("terms", {
    validate(value) {
        return {
            required: true,
            valid: value == true,
        };
    },
    message: "O campo {_field_} é obrigatório",
    computesRequired: true,
});

export const regex = extend("regex", rule_regex);

export const between = extend("between", rule_between);

export const alpha = extend("alpha", rule_alpha);

export const integer = extend("integer", rule_integer);

export const digits = extend("digits", rule_digits);

export const alphaDash = extend("alpha-dash", rule_alpha_dash);

export const alphaNum = extend("alpha-num", rule_alpha_num);

export const length = extend("length", rule_length);

export const positive = extend("positive", {
    validate: validatorPositive,
    message: "Please enter positive number!",
});

export const credit = extend("credit-card", {
    validate: validatorCreditCard,
    message: "It is not valid credit card!",
});

export const password = extend("password", {
    validate: validatorPassword,
    message:
        "Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit",
});

export const url = extend("url", {
    validate: validatorUrlValidator,
    message: "URL is invalid",
});

// Install English and Arabic localizations.
localize({
    en: {
        messages: {
            email: "O campo {_field_} deve ser um email válido",
            min: "O campo {_field_} é muito curto.",
        },
        names: {
            email: "email",
            password: "senha",
        },
        fields: {
            senha: {
                min: "O campo {_field_} é muito curto.",
            },
            "confirmar senha": {
                confirmed: "Os campos {_field_} e senha devem ser iguais.",
            },
            "confirmar nova senha": {
                confirmed: "Os campos {_field_} e nova senha devem ser iguais.",
            },
            email: "O campo {_field_} deve ser um email válido",
        },
    },
});
